<template>
  <div class="emission_reduce_page" v-loading="loading">
    <dec-block-title>减排累计TOP5(t)</dec-block-title>
    <dv-charts :option="option"/>
  </div>
</template>

<script>
import decBlockTitle from '../decorations/blockTitle2'
import {isEmptyObj} from "@/util";
// import { queryToMD5 } from "@/utils/md5_query"

export default {
  name: 'emissionReduce',
  components: {
    decBlockTitle
  },
  props: {
    showMore: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    allTopList: {
      type: Object,
      default: () => {
        return {}
      }
    },
    onlineDevice: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  data() {
    return {
      option: {},
      loading: true
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    allTopList: {
      handler: function () {
        this.init()
      },
      deep: true

    },
    onlineDevice: {
      handler: function () {
        this.init()
      },
      deep: true
    },
  },
  methods: {
    init() {
      this.loading = true
      if (isEmptyObj(this.allTopList) || isEmptyObj(this.onlineDevice))
        return
      // 减排累计
      this.allTopList.redEmissions.map((item) => {
        this.onlineDevice.device.map((vo) => {
          if (item.K.split('/').join('') === vo.K.split('/').join('')) {
            item.N = vo.N
          }
        })
      })
      if (this.allTopList.redEmissions.length === 0) {
        this.option = {}
      }
      this.getData()
    },
    getData() {
      if (!this.showMore) {
        this.option = {
          // color: '#fff',
          xAxis: {
            min: 0,
            axisLine: {
              style: {
                stroke: '#324D4D',
                lineDash: [5, 2]
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              style: {
                fontSize: 9,
                fill: '#D0D0D0'
              }
            },
            splitLine: {
              stroke: 'red',
              lineWidth: 1
            },
            data: this.allTopList.redEmissions.filter((item, index) => index < 5).map(item => {
              return item.N || ""
            })
          },
          yAxis: {
            min: 0,
            data: 'value',
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              style: {
                fontSize: 9,
                fill: '#D0D0D0'
              }
            },
            splitLine: {
              style: {
                stroke: '#324D4D',
                lineWidth: 1,
                lineDash: [5, 2]
              }
            }
          },
          series: [
            {
              xAxisIndex: 0,
              yAxisIndex: 0,
              data: this.allTopList.redEmissions.filter((item, index) => index < 5).map(item => {
                return item.V
              }),
              type: 'bar',
              barWidth: 15,
              gradient: {
                color: ['rgba(104,224,207,1)', 'rgba(32,156,255,1)']
              }
            }
          ],
          grid: {
            left: 30,
            top: 20,
            right: 0,
            bottom: 20
          }
        }
      } else {
        this.option = {
          // color: '#fff',
          yAxis: {
            axisLine: {
              style: {
                stroke: '#324D4D',
                lineDash: [5, 2]
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              style: {
                fontSize: 11,
                fill: '#D0D0D0',
                rotate: 45
              }
            },
            splitLine: {
              show: false
            },
            data: this.allTopList.redEmissions.map(item => {
              return item.N || ""
            }).reverse()
          },
          xAxis: {
            min: 0,
            data: 'value',
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              style: {
                fontSize: 9,
                fill: '#D0D0D0'
              }
            },
            splitLine: {
              style: {
                stroke: '#324D4D',
                lineWidth: 1,
                lineDash: [5, 2]
              }
            }
          },
          series: [
            {
              xAxisIndex: 0,
              yAxisIndex: 0,
              data: this.allTopList.redEmissions.map(item => {
                return item.V
              }).reverse(),
              type: 'bar',
              barWidth: 15,
              gradient: {
                color: ['rgba(104,224,207,1)', 'rgba(32,156,255,1)']
              }
            }
          ],
          grid: {
            right: 5,
            left: 45,
            bottom: 20,
            top: 30
          }
        }
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
.emission_reduce_page {
  width: 100%;
  // height: 33%;
  box-sizing: border-box;

  .dv-charts-container {
    height: calc(100% - 45px);
  }
}
</style>
